import React, { useEffect, useState } from 'react'
import { Col, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { getImageUrl, showToast, convertArrayToObject } from 'helpers/utils';
import Editor from 'components/Common/Editor';
import TabCard from 'pages/HomepageSetting/Tabs/Tab/TabCard';
import { Divider } from '@material-ui/core';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { API_URL } from 'helpers/api_helper';

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const CreateUpdate = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [finalData, setFinalData] = useState(null)
  const [errors, setErrors] = useState([])
  const [isHasFeature, setIsHasFeature] = useState(false)
  const [isHasService, setIsHasService] = useState(false)
  const [fileList, setFileList] = useState([])
  const methods = useForm();
  const [customActiveTab, setcustomActiveTab] = useState(1);

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const { mutate, isLoading: submitLoading } = useMutation(async (params) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (value instanceof FileList) {
        formData.append(key, value[0])
      } else {
        if (value !== null) {
          formData.set(key, value)
        }
      }
    }
    fileList.map(item => item.filename).map((image, i) => {
      formData.append(`images[${i}]`, image)
    })
    if (data) {
      formData.append('_method', 'put')
    }
    return await data ? api.updateProject(data.id, formData) : api.createProject(formData)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit blog', 'error')
    }
  })

  useEffect(() => {
    setErrors([]);
    if (data) {
      let services = JSON.parse(data?.detail?.services).map((item, i) => {
          let obj = {};
          obj[`services_${i + 1}_image`] = item.image;
          obj[`services_${i + 1}_title`] = item.title;
          obj[`services_${i + 1}_description`] = item.description;
          return obj;
      });

      let features = JSON.parse(data?.detail?.features).map((item, i) => {
          let obj = {};
          obj[`features_${i + 1}_icon`] = item.icon;
          obj[`features_${i + 1}_title`] = item.title;
          obj[`features_${i + 1}_description`] = item.description;
          return obj;
      });
      methods.reset({
        ...data?.detail,
        ...data,
        ...convertArrayToObject(services),
        ...convertArrayToObject(features),
      });
      setFinalData({
        ...data?.detail,
        ...data,
        ...convertArrayToObject(services),
        ...convertArrayToObject(features),
      })
      let images = data.images.map(image => ({
        uid: image.id,
        percent: 100,
        name: image.path,
        status: 'done',
        url: getImageUrl(image.path),
        filename: image.path,
      }))
      setFileList(images)
      setIsHasFeature(data?.detail.is_has_feature)
      setIsHasService(data?.detail.is_has_service)
    }
  }, [data, modal])

  const handleChange = ({ fileList: newFileList }) => {
    newFileList.map(item => {
      item.status == 'done' ? item.filename = item.response.filename : item;
    })
    setFileList(newFileList)
  };

  return (
    <Modal
      size="md"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Project' : 'Create Project'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(mutate)}>
            <Row>
              <Col>
                <div className="mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    {...methods.register('name', { required: true })}
                  />
                  {methods.formState.errors?.name && <small className="text-danger">This field is required</small>}
                  {errors?.name && <span className="form-text text-danger">{errors?.name[0]}</span>}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    {...methods.register('state', { required: true })}
                  />
                  {methods.formState.errors?.state && <small className="text-danger">This field is required</small>}
                  {errors?.state && <span className="form-text text-danger">{errors?.state[0]}</span>}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label>Thumbnail</label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Thumbnail"
                    {...methods.register('thumbnail')}
                  />
                  {methods.formState.errors?.thumbnail && <small className="text-danger">This field is required</small>}
                  {errors?.thumbnail && <span className="form-text text-danger">{errors?.thumbnail[0]}</span>}
                </div>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={submitLoading} onClick={methods.handleSubmit(mutate)}>
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(CreateUpdate);